<template>
  <DistributionRowBase
    :img="image"
    :description="person.full_name"
    :share="share"
    :person="true"
    @remove="$emit('removePerson')"
    @increment="$emit('incrementPerson')"
    @decrement="$emit('decrementPerson')"
    @value="$emit('valuePerson', $event)"
  >
  </DistributionRowBase>
</template>

<script>
import DistributionRowBase from './RowBase'

export default {
  name: 'DistributionRowPerson',
  props: ['person', 'primary', 'backup'],
  components: {
    DistributionRowBase
  },
  computed: {
    image() {
      if (this.person.gender === 'Male')
        return 'https://mylastwill.s3.amazonaws.com/static/img/button-male.png'
      return 'https://mylastwill.s3.amazonaws.com/static/img/button-female.png'
    },
    share() {
      if (this.primary) return this.person.distribution_primary_percentage
      else if (this.backup) return this.person.distribution_backup_percentage
      return null
    }
  },
  methods: {}
}
</script>
