<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <DistributionInputOptions
        :children="false"
        @addPerson="addPerson"
        @addGroup="addGroup"
        @addCharity="addCharity"
      ></DistributionInputOptions>
      <DistributionTable
        v-show="people.length || groups.length || charities.length"
        :people="people"
        :groups="groups"
        :charities="charities"
        :percentage="percentage"
        :remainingPercentage="remainingPercentage"
        :primary="false"
        :backup="true"
        @incrementPerson="incrementPerson"
        @decrementPerson="decrementPerson"
        @valuePerson="setValuePerson"
        @removePerson="removePerson"
        @incrementGroup="incrementGroup"
        @decrementGroup="decrementGroup"
        @valueGroup="setValueGroup"
        @removeGroup="removeGroup"
        @incrementCharity="incrementCharity"
        @decrementCharity="decrementCharity"
        @valueCharity="setValueCharity"
        @removeCharity="removeCharity"
        @splitEqually="splitEqually"
      ></DistributionTable>
      <DistributionPersonModal
        v-show="show.personModal"
        :peopleOptionList="peopleOptionList"
        :primary="false"
        :backup="true"
        @close="show.personModal = false"
      ></DistributionPersonModal>
      <DistributionGroupModal
        v-show="show.groupModal"
        :primary="false"
        :backup="true"
        @close="show.groupModal = false"
      ></DistributionGroupModal>
      <DistributionCharityModal
        v-show="show.charityModal"
        :primary="false"
        :backup="true"
        @close="show.charityModal = false"
      ></DistributionCharityModal>
      <DeleteModal
        v-show="show.deleteModal"
        @close="closeDeleteModal"
        @confirm="deleteBeneficiary"
        v-bind:bodyText="deleteText"
      ></DeleteModal>
      <SplitBackupWarning
        v-if="show.warningModal"
        @close="show.warningModal = false"
      />
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import DistributionTable from './Tables/Table'
import DistributionInputOptions from './Tables/InputOptions'
import DeleteModal from '@/common/components/DeleteModal'
import DistributionPersonModal from './Modals/Person'
import DistributionGroupModal from './Modals/Group'
import DistributionCharityModal from './Modals/Charity'
import SplitBackupWarning from '@/views/pages/DistributionPage/Questions/Modals/SplitBackupWarning'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'DistributionSplitBackup',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    DistributionTable,
    DistributionInputOptions,
    DistributionPersonModal,
    DistributionGroupModal,
    DistributionCharityModal,
    DeleteModal,
    SplitBackupWarning
  },
  mounted() {
    if (this.partner && this.partner.distribution_sole_primary) {
      this.show.warningModal = true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('savePeople')
    this.$store.dispatch('saveGroups')
    this.$store.dispatch('saveCharities')
    next()
  },
  computed: {
    isValidated() {
      var result = true
      this.people.forEach((person) => {
        if (
          Number(person.distribution_backup_percentage) === 0 ||
          isNaN(Number(person.distribution_backup_percentage))
        ) {
          result = false
        }
      })
      this.groups.forEach((group) => {
        if (
          Number(group.distribution_backup_percentage) === 0 ||
          isNaN(Number(group.distribution_backup_percentage))
        ) {
          result = false
        }
      })
      this.charities.forEach((charity) => {
        if (
          Number(charity.distribution_backup_percentage) === 0 ||
          isNaN(Number(charity.distribution_backup_percentage))
        ) {
          result = false
        }
      })
      if (this.percentage !== 100) result = false
      return result
    },
    heading() {
      return 'Who are the substitute beneficiaries?'
    },
    number() {
      var number = 1
      if (this.partner) number += 1
      if (this.$store.getters.peopleDistributionPrimary) {
        number += this.$store.getters.peopleDistributionPrimary.length
      }
      if (this.$store.getters.groupsDistributionPrimary) {
        number += this.$store.getters.groupsDistributionPrimary.length
      }
      if (
        !this.groupsPrimary.length &&
        this.peoplePrimary.length === 1 &&
        this.peoplePrimary[0].distribution_backup_children_no
      ) {
        number -= 1
      }
      return number + 2
    },
    partner() {
      return this.$store.getters.partner
    },
    subHeading() {
      var string =
        'Please add details of friends, family or charities who you would like to benefit if ' +
        this.listPartnerPeopleGroupsCharities(
          this.partner && this.partner.distribution_sole_primary
            ? this.partner
            : null,
          this.$store.getters.peopleDistributionPrimary,
          this.$store.getters.groupsDistributionPrimary,
          this.$store.getters.charitiesDistributionPrimary,
          ' and ',
          true
        )
      if (
        this.$store.getters.peopleDistributionPrimary.length +
          this.$store.getters.groupsDistributionPrimary.length +
          this.$store.getters.charitiesDistributionPrimary.length ===
        1
      ) {
        string += ' etc, have '
      } else string += ' etc, have all'
      return string + ' passed away before you.'
    },
    forwardTo() {
      return '/distribution/minors'
    },
    backTo() {
      if (
        !this.groupsPrimary.length &&
        this.peoplePrimary.length === 1 &&
        this.peoplePrimary[0].distribution_backup_children_no
      ) {
        return `/distribution/primary_person/${this.peoplePrimary[0].full_name}/${this.peoplePrimary[0].id}`
      }
      return '/distribution/all_fail'
    },
    details() {
      return this.$store.getters.details
    },
    people() {
      return this.$store.getters.people.filter(
        (person) => person.distribution_backup
      )
    },
    groups() {
      return this.$store.getters.groups.filter(
        (group) => group.distribution_backup
      )
    },
    charities() {
      return this.$store.getters.charities.filter(
        (charity) => charity.distribution_backup
      )
    },
    peoplePrimary() {
      return this.$store.getters.peopleDistributionPrimary
    },
    groupsPrimary() {
      return this.$store.getters.groupsDistributionPrimary
    },
    peopleOptionList() {
      return this.$store.getters.people.filter(
        (person) =>
          !person.distribution_sole_primary && !person.distribution_backup
      )
    },
    deleteText() {
      if (this.selected.person) {
        return (
          'Remove ' + this.selected.person.full_name + ' from you distribution?'
        )
      } else if (this.selected.group) {
        return (
          'Remove ' +
          this.selected.group.group_name +
          ' from your distribution?'
        )
      } else if (this.selected.charity) {
        return (
          'Remove ' +
          this.selected.charity.charity_name +
          ' from your distribution?'
        )
      }
      return ''
    },
    percentage() {
      var total = 0
      this.people.forEach((person) => {
        total += Number(person.distribution_backup_percentage)
      })
      this.groups.forEach((person) => {
        total += Number(person.distribution_backup_percentage)
      })
      this.charities.forEach((charity) => {
        total += Number(charity.distribution_backup_percentage)
      })
      if (total % 2 !== 0) return total.toFixed(2)
      return total
    },
    remainingPercentage() {
      if (this.percentage % 2 !== 0) {
        return Number(100 - this.percentage).toFixed(2)
      }
      return 100 - this.percentage
    },
    totalBeneficiaries() {
      var count = 0
      count += this.people.length + this.groups.length + this.charities.length
      return count
    },
    fixedNumber() {
      if (100 % (100 / this.totalBeneficiaries) === 0) return 0
      return 2
    }
  },
  data() {
    return {
      show: {
        personModal: false,
        groupModal: false,
        charityModal: false,
        deleteModal: false,
        intro: true,
        warningModal: false
      },
      selected: {
        person: null,
        group: null,
        charity: null
      }
    }
  },
  methods: {
    splitEqually() {
      var calc = 100 / this.totalBeneficiaries
      var share = calc.toFixed(this.fixedNumber)
      var i = 0

      var peopleCopy = []

      this.people.forEach((person) => {
        peopleCopy.push(Object.assign({}, person))
      })

      peopleCopy.forEach((person) => {
        i += 1
        var thisShare = share
        if (i === this.totalBeneficiaries) {
          thisShare =
            100 -
            share * (this.totalBeneficiaries - 1).toFixed(this.fixedNumber)
        }
        let personCopy = Object.assign({}, person)
        personCopy.distribution_backup_percentage = String(thisShare)
        this.$store.commit('personAdd', personCopy)
      })

      var groupsCopy = []

      this.groups.forEach((group) => {
        groupsCopy.push(Object.assign({}, group))
      })

      groupsCopy.forEach((group) => {
        i += 1
        var thisShare = share
        if (i === this.totalBeneficiaries) {
          thisShare =
            100 -
            share * (this.totalBeneficiaries - 1).toFixed(this.fixedNumber)
        }
        group.distribution_backup_percentage = String(thisShare)
        this.$store.commit('groupAdd', group)
      })

      var charitiesCopy = []

      this.charities.forEach((charity) => {
        charitiesCopy.push(Object.assign({}, charity))
      })

      charitiesCopy.forEach((charity) => {
        i += 1
        var thisShare = share
        if (i === this.totalBeneficiaries) {
          thisShare =
            100 -
            share * (this.totalBeneficiaries - 1).toFixed(this.fixedNumber)
        }
        charity.distribution_backup_percentage = String(thisShare)
        this.$store.commit('charityAdd', charity)
      })
    },
    addPerson() {
      this.show.personModal = true
    },
    addGroup() {
      this.show.groupModal = true
    },
    addCharity() {
      this.show.charityModal = true
    },
    clearSelected() {
      this.selected.person = null
      this.selected.group = null
      this.selected.charity = null
    },
    closeDeleteModal() {
      this.clearSelected()
      this.show.deleteModal = false
    },
    deleteBeneficiary() {
      if (this.selected.person) {
        this.selected.person.distribution_backup = false
        this.selected.person.distribution_backup_percentage = '0'
        this.selected.person.distribution_backup_person = null
        this.selected.person.distribution_backup_group = ''
        this.selected.person.distribution_backup_children = false
        this.selected.person.distribution_backup_other_beneficiaries = false
        this.selected.person.distribution_backup_children_no = false
        this.$store.commit('personAdd', this.selected.person)
        this.$notification.success('Person removed')
      } else if (this.selected.group) {
        this.$store.dispatch('deleteGroupFromServer', this.selected.group.id)
        this.$notification.success('Group removed')
      } else if (this.selected.charity) {
        this.$store.dispatch(
          'deleteCharityFromServer',
          this.selected.charity.id
        )
        this.$notification.success('Charity removed')
      }
      this.closeDeleteModal()
    },
    removePerson(value) {
      this.selected.person = value
      this.show.deleteModal = true
    },
    removeGroup(value) {
      this.selected.group = value
      this.show.deleteModal = true
    },
    removeCharity(value) {
      this.selected.charity = value
      this.show.deleteModal = true
    },
    incrementPerson(person) {
      var personCopy = Object.assign({}, person)

      if (Number(personCopy.distribution_backup_percentage) + 10 <= 100) {
        personCopy.distribution_backup_percentage = Number(
          Number(personCopy.distribution_backup_percentage) + 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('personAdd', personCopy)
      }
    },
    decrementPerson(person) {
      var personCopy = Object.assign({}, person)
      if (Number(personCopy.distribution_backup_percentage) - 10 >= 0) {
        personCopy.distribution_backup_percentage = Number(
          Number(personCopy.distribution_backup_percentage) - 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('personAdd', personCopy)
      }
    },
    setValuePerson(person, value) {
      var personCopy = Object.assign({}, person)
      if (value && value.includes('.00')) {
        personCopy.distribution_backup_percentage = value.replace('.00', '')
      } else if (value && value[0] === '0') {
        personCopy.distribution_backup_percentage = value.slice(1)
      } else {
        personCopy.distribution_backup_percentage = value
      }
      this.$store.commit('personAdd', personCopy)
    },
    incrementGroup(group) {
      var groupCopy = Object.assign({}, group)

      if (Number(groupCopy.distribution_backup_percentage) + 10 <= 100) {
        groupCopy.distribution_backup_percentage = Number(
          Number(groupCopy.distribution_backup_percentage) + 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('groupAdd', groupCopy)
      }
    },
    decrementGroup(group) {
      var groupCopy = Object.assign({}, group)

      if (Number(groupCopy.distribution_backup_percentage) - 10 >= 0) {
        groupCopy.distribution_backup_percentage = Number(
          Number(groupCopy.distribution_backup_percentage) - 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('groupAdd', groupCopy)
      }
    },
    setValueGroup(group, value) {
      if (value && value.includes('.00')) {
        group.distribution_backup_percentage = value.replace('.00', '')
      } else {
        group.distribution_backup_percentage = value
      }
      this.$store.commit('groupAdd', group)
    },
    incrementCharity(charity) {
      var charityCopy = Object.assign({}, charity)

      if (Number(charityCopy.distribution_backup_percentage) + 10 <= 100) {
        charityCopy.distribution_backup_percentage = Number(
          Number(charityCopy.distribution_backup_percentage) + 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('charityAdd', charityCopy)
      }
    },
    decrementCharity(charity) {
      var charityCopy = Object.assign({}, charity)

      if (Number(charityCopy.distribution_backup_percentage) - 10 >= 0) {
        charityCopy.distribution_backup_percentage = Number(
          Number(charityCopy.distribution_backup_percentage) - 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('charityAdd', charityCopy)
      }
    },
    setValueCharity(charity, value) {
      if (value && value.includes('.00')) {
        charity.distribution_backup_percentage = value.replace('.00', '')
      } else {
        charity.distribution_backup_percentage = value
      }
      this.$store.commit('charityAdd', charity)
    }
  }
}
</script>
