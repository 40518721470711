<template>
  <div class="row fadeIn-5">
    <div class="col-lg-12">
      <div class="app-table">
        <table class="gifts-table">
          <thead class="gifts-wrap-title-main">
            <tr>
              <th class="gift-description">Beneficiary</th>
              <th class="gift-beneficiary">Percentage</th>
            </tr>
          </thead>
          <tbody>
            <DistributionRowPerson
              v-for="(person, index) in people"
              :key="person.full_name + index"
              :person="person"
              :primary="primary"
              :backup="backup"
              @removePerson="$emit('removePerson', person)"
              @incrementPerson="$emit('incrementPerson', person)"
              @decrementPerson="$emit('decrementPerson', person)"
              @valuePerson="$emit('valuePerson', person, $event)"
            ></DistributionRowPerson>
            <DistributionRowGroup
              v-for="(group, index) in groups"
              :key="group.group_name + index"
              :group="group"
              :primary="primary"
              :backup="backup"
              @removeGroup="$emit('removeGroup', group)"
              @incrementGroup="$emit('incrementGroup', group)"
              @decrementGroup="$emit('decrementGroup', group)"
              @valueGroup="$emit('valueGroup', group, $event)"
            ></DistributionRowGroup>
            <DistributionRowCharity
              v-for="(charity, index) in charities"
              :key="charity.charity_name + index"
              :charity="charity"
              :primary="primary"
              :backup="backup"
              @removeCharity="$emit('removeCharity', charity)"
              @incrementCharity="$emit('incrementCharity', charity)"
              @decrementCharity="$emit('decrementCharity', charity)"
              @valueCharity="$emit('valueCharity', charity, $event)"
            ></DistributionRowCharity>
            <tr>
              <td colspan="5" class="totals">
                <div class="share-total">
                  <h4>
                    Total distributed:
                    <span class="c-primary">{{ percentage }}%</span>
                  </h4>
                  <span id="percentage-left-text"
                    >{{ remainingPercentage }}% remaining</span
                  >
                </div>

                <div
                  v-on:click="$emit('splitEqually')"
                  class="btn btn-medium btn--primary waves-effect waves-dark"
                >
                  <span class="text">Split Equally</span>
                  <span class="semicircle"></span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DistributionRowPerson from './RowPerson'
import DistributionRowCharity from './RowCharity'
import DistributionRowGroup from './RowGroup'

export default {
  name: 'DistributionTable',
  props: [
    'people',
    'charities',
    'groups',
    'percentage',
    'remainingPercentage',
    'primary',
    'backup'
  ],
  components: {
    DistributionRowPerson,
    DistributionRowGroup,
    DistributionRowCharity
  },
  computed: {},
  methods: {}
}
</script>
