<template>
  <tr class="app-table-row">
    <td class="gift-description">
      <img alt="add cash gift" :src="img" />
      <h5 class="inline-block align-middle large-touch">
        {{ description }}
        <span
          v-if="!distProtected"
          v-on:click="$emit('remove')"
          class="gift-del large-touch"
          title="Remove from distribution"
        >
          <i v-on:click="$emit('remove')" class="fa fa-eraser"></i>
        </span>
      </h5>
      <ErrorPop
        v-show="error.zero !== null"
        :message="error.zero"
        :displayClose="true"
        @clearError="error.zero = null"
      ></ErrorPop>
    </td>
    <td class="beneficiary-percentage">
      <div class="quantity">
        <a
          v-on:click="$emit('decrement')"
          href="javascript:void(0)"
          class="quantity-minus"
          >-</a
        >
        <input
          :value="share"
          type="text"
          maxlength="5"
          v-on:input="$emit('value', $event.target.value)"
          v-on:focus="$emit('value', $event.target.value)"
        />
        <ErrorPop
          v-show="error.NaN !== null"
          :message="error.NaN"
          :displayClose="false"
          :left="true"
          @clearError="error.NaN = null"
        ></ErrorPop>
        <a
          v-on:click="$emit('increment')"
          href="javascript:void(0)"
          class="quantity-plus"
          >+</a
        >
      </div>
    </td>
  </tr>
</template>

<script>
import ErrorPop from '@/common/ui/ErrorPop'

export default {
  name: 'DistributionRowBase',
  props: [
    'img',
    'description',
    'beneficiary',
    'share',
    'distProtected',
    'distProtectedAmount',
    'person'
  ],
  components: {
    ErrorPop
  },
  watch: {
    share: {
      deep: true,
      immediate: true,
      handler(value) {
        if (isNaN(Number(value))) {
          this.error.NaN = 'Must be a number'
        } else if (
          this.distProtected &&
          this.share < this.distProtectedAmount
        ) {
          this.error.NaN =
            'Must be great than ' + this.distProtectedAmount + '%'
        } else {
          this.error.NaN = null
        }
        this.displayAutoError(value)
      }
    }
  },
  data() {
    return {
      error: {
        NaN: null,
        zero: null
      }
    }
  },
  methods: {
    displayAutoError(value) {
      setTimeout(() => {
        if (value === '0' || value === '') {
          if (this.person) {
            this.error.zero = `Assign a percentage to ${
              this.description.split(' ')[0]
            } or remove from distribution`
          } else {
            this.error.zero = `Assign a percentage to ${this.description} or remove from distribution`
          }
          setTimeout(() => {
            this.error.zero = null
          }, 9000)
        } else {
          this.error.zero = null
        }
      }, 6000)
    }
  }
}
</script>

<style scoped>
.large-touch {
  padding: 20px;
}
</style>
