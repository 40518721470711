<template>
  <DistributionRowBase
    :img="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-group.png'"
    :description="capitalise(group.group_name)"
    :share="share"
    @remove="$emit('removeGroup')"
    @increment="$emit('incrementGroup')"
    @decrement="$emit('decrementGroup')"
    @value="$emit('valueGroup', $event)"
  >
  </DistributionRowBase>
</template>

<script>
import DistributionRowBase from './RowBase'
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'DistributionRowGroup',
  mixins: [TextFormat],
  props: ['group', 'primary', 'backup'],
  components: {
    DistributionRowBase
  },
  computed: {
    share() {
      if (this.primary) return this.group.distribution_percentage
      else if (this.backup) return this.group.distribution_backup_percentage
      return null
    }
  }
}
</script>
