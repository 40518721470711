var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row input-option-wrapper"},[(_vm.$store.getters.personalDetails.children !== 'No')?_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('addChildren')}}},[_vm._m(0),_vm._m(1)])]):_vm._e(),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){return _vm.$emit('addPerson')}}},[_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){return _vm.$emit('addGroup')}}},[_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){return _vm.$emit('addCharity')}}},[_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add children","src":"https://mylastwill.s3.amazonaws.com/static/img/button-distribution-tochildren.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("Add Children")]),_c('i',{staticClass:"fa fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add person","src":"https://mylastwill.s3.amazonaws.com/static/img/button-distribution-person.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("Add Person")]),_c('i',{staticClass:"fa fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add group","src":"https://mylastwill.s3.amazonaws.com/static/img/button-distribution-group.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("Add Group")]),_c('i',{staticClass:"fa fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add charity","src":"https://mylastwill.s3.amazonaws.com/static/img/button-distribution-charity.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("Add Charity")]),_c('i',{staticClass:"fa fa-plus"})])
}]

export { render, staticRenderFns }