<template>
  <BaseModalSmall
    @close="close"
    v-show="show"
    :show="show"
    v-bind:isValidated="validateForm"
    @confirm="save"
  >
    <template v-slot:header>{{ title }}</template>
    <template v-slot:body>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <CharitiesSelect @selected="setCharity" :colour="'grey'" />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="input-container">
            <input
              v-model="data.charity_name"
              type="text"
              class="input-standard-grey validation"
              placeholder="Charity Name"
              required
              id="name"
            />
            <label for="name">Charity Name</label>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="input-container">
            <input
              v-model="data.charity_number"
              type="text"
              class="input-standard-grey validation"
              placeholder="Charity Number"
              required
              id="number"
            />
            <label for="number">Charity Number</label>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:button>
      {{ buttonText }}
    </template>
  </BaseModalSmall>
</template>

<script>
import CharitiesSelect from '../../../../../common/components/CharitiesSelect'
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import { http } from '@/services'

export default {
  name: 'DistributionCharityModal',
  components: {
    BaseModalSmall,
    CharitiesSelect
  },
  props: ['show', 'primary', 'backup'],
  watch: {
    primary: {
      immediate: true,
      handler(value) {
        if (value) this.data.distribution_primary = true
      }
    },
    backup: {
      immediate: true,
      handler(value) {
        if (value) this.data.distribution_backup = true
      }
    }
  },
  computed: {
    validateForm() {
      if (this.data.charity_name && this.data.charity_number) return true
      return false
    },
    title() {
      return 'Add Recipient (Charity)'
    },
    buttonText() {
      return 'Add Charity'
    }
  },
  data() {
    return {
      data: {
        charity_name: '',
        charity_number: '',
        distribution_primary: false,
        distribution_backup: false
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.clearAll()
    },
    save(value) {
      this.post()
    },
    clearAll() {
      this.data.charity_name = ''
      this.data.charity_number = ''
    },
    setCharity(value) {
      if (value) {
        this.data.charity_name = value.name
        this.data.charity_number = value.number
      } else {
        this.clearAll()
      }
    },
    post() {
      http
        .post('/wills/api/charity', this.data)
        .then((response) => {
          this.$store.commit('charityAdd', response.data)
          this.close()
          this.$notification.success('Charity Added')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
