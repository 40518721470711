<template>
  <DistributionRowBase
    :img="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-charity.png'"
    :description="charity.charity_name"
    :share="share"
    :distProtected="charity.charity_protected"
    :distProtectedAmount="charityInCoupon"
    @remove="$emit('removeCharity')"
    @increment="$emit('incrementCharity')"
    @decrement="$emit('decrementCharity')"
    @value="$emit('valueCharity', $event)"
  >
  </DistributionRowBase>
</template>

<script>
import DistributionRowBase from './RowBase'

export default {
  name: 'DistributionRowCharity',
  props: ['charity', 'primary', 'backup'],
  components: {
    DistributionRowBase
  },
  computed: {
    share() {
      if (this.primary) return this.charity.distribution_percentage
      else if (this.backup) return this.charity.distribution_backup_percentage
      return null
    },
    checkoutBasket() {
      return this.$store.getters.checkoutBasket
    },
    charityInCoupon() {
      if (
        this.checkoutBasket &&
        this.checkoutBasket.coupon &&
        this.checkoutBasket.coupon.charity
      )
        return this.checkoutBasket.coupon.charity.min_percentage
      return null
    }
  }
}
</script>
