<template>
  <BaseModalSmall
    @close="close"
    v-show="show"
    :show="show"
    v-bind:isValidated="validateForm"
    v-bind:loading="showProgress"
    @confirm="save"
  >
    <template v-slot:header>{{ title }}</template>
    <template v-slot:body>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="input-container">
            <GroupSelect
              :grp="data.group_name"
              :required="true"
              @selected="data.group_name = $event"
            ></GroupSelect>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:button>
      {{ buttonText }}
    </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import GroupSelect from '@/common/components/GroupSelect'
import { http } from '@/services'

export default {
  name: 'DistributionGroupModal',
  components: {
    BaseModalSmall,
    GroupSelect
  },
  props: ['show', 'primary', 'backup'],
  watch: {
    primary: {
      immediate: true,
      handler(value) {
        if (value) this.data.distribution_primary = true
      }
    },
    backup: {
      immediate: true,
      handler(value) {
        if (value) this.data.distribution_backup = true
      }
    }
  },
  computed: {
    validateForm() {
      if (this.data.group_name) return true
      return false
    },
    title() {
      return 'Add Recipient (Group)'
    },
    buttonText() {
      return 'Add Group'
    }
  },
  data() {
    return {
      data: {
        group_name: '',
        distribution_primary: false,
        distribution_backup: false
      },
      showProgress: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.clearAll()
    },
    save(value) {
      this.post()
    },
    clearAll() {
      this.data.group_name = ''
    },
    post() {
      http
        .post('/wills/api/group', this.data)
        .then((response) => {
          this.$store.commit('groupAdd', response.data)
          this.close()
          this.$notification.success('Group Added')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
