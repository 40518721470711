<template>
  <BaseModalSmall
      @close="close"
      v-bind:isValidated="true"
      @confirm="close"
      :noSlide="true"
  >
    <template v-slot:header>Note</template>
    <template v-slot:body>
      <div>
        <p>
          Users who are looking to make truly 'Mirrored Wills' often leave 50% the their family if all else fails and
          50% to their partners family. This means that everything would be split the same way, irrespective of who's
          Will is being read.
        </p>
      </div>
    </template>
    <template v-slot:button> I Understand </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'SplitBackupWarning',
  components: {
    BaseModalSmall
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
