<template>
  <div class="row input-option-wrapper">
    <div
      v-if="$store.getters.personalDetails.children !== 'No'"
      class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"
    >
      <div v-on:click.stop="$emit('addChildren')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add children"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-distribution-tochildren.png"
          />
        </div>
        <div class="text">
          <p>Add Children</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>

    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding">
      <div v-on:click="$emit('addPerson')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add person"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-distribution-person.png"
          />
        </div>
        <div class="text">
          <p>Add Person</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding">
      <div v-on:click="$emit('addGroup')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add group"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-distribution-group.png"
          />
        </div>
        <div class="text">
          <p>Add Group</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding">
      <div v-on:click="$emit('addCharity')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add charity"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-distribution-charity.png"
          />
        </div>
        <div class="text">
          <p>Add Charity</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DistributionInputOptions',
  props: ['children']
}
</script>
